let routeName = 'module->Individual->Profile';

export const module = {
  info: {
    fullName: 'Individual-Profile',
    namespace: 'Individual',
    name: 'Profile',
    caption: 'Profile',
    routeName: 'module->Individual->Profile',
    viewGroup: 'system'
  },
  vuexModules: ['api', 'state'],
  mainRoute: '/cpanel',
  sideMenu: {
    topSeparator: false,
    caption: 'Profile',
    icon: 'el-icon-folder',
    routeName: 'list->page',
    routeParams: { listPage: 1}
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  },
  router: {
    mainRouteName: 'portal-cpanel',
    routes: {
      path: 'profile',
      name: routeName,
      component: () => import('./View.vue'),
      children: [
        {
          path: 'create',
          name: routeName + '->create',
          component: () => import('./create/View'),
        },
        {
          path: 'list',
          name: routeName + '->list',
          component: () => import('./list/View'),
          children: [
            {
              path: ':listPage',
              name: routeName + '->list->page',
              component: () => import('./list/page/View'),
            }
          ]
        },
        {
          path: ':entityTitle',
          name: routeName + '->view',
          component: () => import('./view/View.vue'),
        }
      ]
    }
  }
}

export default module
